import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ArrowLeft } from "../../SvgIcons/allIcons";
import Sidebar from "../sidebar/Sidebar";
import NavigateBack from "../../components/NavigateBack";

const ChooseSize = () => {
  return (
    <div className="mainbox">
      <Sidebar sideBar={true} showFullHeader={true} />
      <div className={"body-content close aa"}>
        <Row>
          <Col xl={6}>
            <NavigateBack>Choose Size</NavigateBack>
          </Col>
          <Col className="text-end" xl={6}>
            <Link to="../create-label" className="userBtn btn-theme">
              Create
            </Link>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={2} className="my-2">
            <div className="grid">
              <div className="labelCard">
                <div className="labelContent">
                  <h3 className="heading colorGreen">1"X1"</h3>
                  <p className="font-20 colorGreen">Square</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} className="my-2">
            <div className="grid">
              <div className="labelCard">
                <div className="labelContent">
                  <h3 className="heading colorGreen">1"X1"</h3>
                  <p className="font-20 colorGreen">Square</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} className="my-2">
            <div className="grid">
              <div className="labelCard">
                <div className="labelContent">
                  <h3 className="heading colorGreen">1"X1"</h3>
                  <p className="font-20 colorGreen">Square</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} className="my-2">
            <div className="grid">
              <div className="labelCard">
                <div className="labelContent">
                  <h3 className="heading colorGreen">1"X1"</h3>
                  <p className="font-20 colorGreen">Square</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} className="my-2">
            <div className="grid">
              <div className="labelCard">
                <div className="labelContent">
                  <h3 className="heading colorGreen">1"X1"</h3>
                  <p className="font-20 colorGreen">Square</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} className="my-2">
            <div className="grid">
              <div className="labelCard">
                <div className="labelContent">
                  <h3 className="heading colorGreen">1"X1"</h3>
                  <p className="font-20 colorGreen">Square</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} className="my-2">
            <div className="grid">
              <div className="labelCard">
                <div className="labelContent">
                  <h3 className="heading colorGreen">1"X1"</h3>
                  <p className="font-20 colorGreen">Square</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChooseSize;
