import * as fabric from "fabric";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import NavigateBack from "../../components/NavigateBack";
import { FeatureUploadLogo } from "../../SvgIcons/allIcons";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import "./createLabel.css";
import FormatEditor from "./FormatEditor";

const CreateLabel = () => {
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (canvasRef.current) {
      const parent = canvasRef.current.parentNode;
      const parentWidth = parent.offsetWidth; // Get parent width
      const parentHeight = parent.offsetHeight; // Get parent height

      const initCanvas = new fabric.Canvas(canvasRef.current, {
        width: parentWidth,
        height: parentHeight,
      });

      initCanvas.renderAll();

      fabric.InteractiveFabricObject.ownDefaults = {
        ...fabric.InteractiveFabricObject.ownDefaults,
        cornerStrokeColor: "#95D390",
        cornerColor: "#95D390",
        transparentCorners: true,
        cornerDashArray: [],
        borderColor: "#95D390",
        borderDashArray: [4],
        borderScaleFactor: 1,
      };

      // Preload the delete icon image
      const deleteIconImg = new Image();
      deleteIconImg.src =
        "https://cdn-icons-png.flaticon.com/512/1828/1828843.png"; // URL to delete icon image

      deleteIconImg.onload = () => {
        // Custom delete control, using preloaded image
        const deleteIcon = (ctx, left, top, styleOverride, fabricObject) => {
          ctx.drawImage(deleteIconImg, left - 12, top - 12, 24, 24); // Draw the delete icon
        };

        fabric.FabricObject.prototype.controls = {
          ...fabric.FabricObject.prototype.controls,
          deleteControl: new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetX: 16,
            offsetY: -16,
            cursorStyle: "pointer",
            mouseUpHandler: (eventData, target) => {
              initCanvas.remove(target);
              initCanvas.requestRenderAll();
            },
            render: deleteIcon,
          }),
        };
      };

      setCanvas(initCanvas);

      return () => {
        initCanvas.dispose();
      };
    }
  }, [canvasRef]);

  /************************Upload Image functionality****************/
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imgElement = document.createElement("img");
        imgElement.src = e.target.result;
        imgElement.onload = () => {
          // Create a Fabric.js image instance
          const imgInstance = new fabric.Image(imgElement, {
            left: (canvas.width - imgElement.width) / 2, // Center horizontally
            top: 0, // Set position at the top
          });

          // Add the image instance to the canvas
          canvas.add(imgInstance);
          canvas.renderAll();
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const onDropRejected = useCallback(() => {
    toastAlert("error", t("errorMsg.fileFormat"));
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: { "image/*": [] },
    multiple: false,
  });

  return (
    <div className="mainbox">
      <Sidebar sideBar={true} showFullHeader={true} />
      <section className={"body-content close aa"}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <NavigateBack>Create Label Profile</NavigateBack>

            <div className="all_Customer d-flex align-items-center">
              <div className="form-group mx-3">
                <input type="checkbox" id="html1" />
                <label htmlFor="html1">
                  <span className="ms-2 allcustomer_Txt">
                    all our customers
                  </span>
                </label>
              </div>
              <button className="userBtn btn-theme fontSize18">Save</button>
            </div>
          </div>

          <div className="basic_Info upload_Logo">
            <div>
              <label htmlFor="name" className="companyLabel">
                Profile Name
              </label>
              <input
                type="text"
                id="name"
                className="commonInput fontSize14"
                placeholder="Enter Profile Name"
              />
            </div>

            <div className="snap_Grid_Parent">
              <div className="row">
                <div className="col-md-6">
                  <div className="center d-flex align-items-center">
                    <label htmlFor="grid" className="snapgrid_Label fontSize14">
                      Snap to Grid:
                    </label>
                    <input type="checkbox" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="center d-flex align-items-center">
                    <label htmlFor="grid" className="snapgrid_Label fontSize14">
                      Border:
                    </label>
                    <input type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="label_Features">
              <div className="row">
                <div className="col-lg-3 pe-lg-0">
                  <div className="features">
                    <div className="featureTxt_Parent">
                      <h2 className="fontSize24">Label Features</h2>
                    </div>
                    <div className="feature_Editor_Child features_Accordion_Parent">
                      <div
                        className="features_UploadLogo_Container mb-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="d-flex  align-items-center">
                          <FeatureUploadLogo />
                          <span className="fontSize16 ms-2">Upload logo</span>
                        </div>
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </div>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="fontSize14">
                            Text
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Item Name
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Price{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Ingredients{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Allergens{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                SKU{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Address{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Employee Name{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Prepped By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Username{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Description{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Net Weight{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="mt-2">
                          <Accordion.Header>Start Date</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Made{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Pull{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Received{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Received On{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Make{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Prep{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Day, Date, Time{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Kill Date{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Picked On{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Manufactured On{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                MFG{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Opened{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="mt-2">
                          <Accordion.Header>
                            Transitional Date/Phase
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Ready{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Thaw{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Temper{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Pull{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Marinate{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Thaw Through{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Marinate Until{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Thaw Until{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="mt-2">
                          <Accordion.Header>End Date</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Must Use By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Use By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Discard{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Expires{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Best By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Freshest By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Freshest Date{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Sell By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Best Before{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Must Sell By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Expire{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Exp{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" className="mt-2">
                          <Accordion.Header>End Date</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Nutritional Fields{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Serving size{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Calories{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Total Fat (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Saturated Fat (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Trans Fat (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Cholesterol (mg){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Carbohydrates (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Protein (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Dietary Fiber (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Total Sugar (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Sodium (mg){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Servings Per Container{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Added Sugar{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Calories From Fat{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Vitamin D{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Vitamin D%{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Iron{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Potassium{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Potassium %{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" className="mt-2">
                          <Accordion.Header>Others</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Barcode – Fixed{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Barcode – Variable{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                QR Code{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Field{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Print Sequence{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Text Field{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Waste Prepped Quantity{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Prepared Temperature{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-lg-0">
                  <div className="label_Preview features">
                    <div className="featureTxt_Parent d-flex justify-content-between align-items-center">
                      <h2 className="fontSize24">Label Preview</h2>
                      <input type="range" className="range_Input" />
                    </div>
                    <div className="feature_Editor_Child">
                      <div className="label_Preview_Child ">
                        <canvas ref={canvasRef} id="canvas" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ps-lg-0">
                  <FormatEditor />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateLabel;
