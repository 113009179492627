import React from "react";
import { Col, Row } from "react-bootstrap";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";

const LabelManagement = () => {
  const isSlider = useSlider();

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <h2 className="mainhead font-24">Label Management</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <Link to="../choose-size" className="userBtn btn-theme">
              Create Label
            </Link>
          </Col>
        </Row>
        <div className="tableOuter mt-4">
          <Row>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label1.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label2.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">2"X1" blank</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label3.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label4.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label5.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label6.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label7.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label8.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label9.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label7.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="/images/label5.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">
                1"X1" Square
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LabelManagement;
