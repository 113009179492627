import { useMutation, useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import CompanyDropDown from "../../components/CompanyDropDown";
import DeleteModal from "../../components/DeleteModal";
import useSlider from "../../hooks/useSlider";
import { deleteDataList, getDataList } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const ReceivingList = () => {
  const isSlider = useSlider();
  const { state } = useLocation();
  const [company, setCompany] = useState(!!state ? state : "");
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["data-list", page, limit, company?.domain],
    queryFn: async () => {
      const resp =
        company?.domain &&
        (await getDataList(
          page,
          limit,
          search,
          constant.DATA_LIST.RECEIVING_LIST,
          company?.domain,
          true,
          true
        ));
      return resp?.data?.data ?? [];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteDataList(id, company?.domain),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("receiving.receivingList")}</h2>
          <div className="d-flex align-items-center gap-2">
            <CompanyDropDown setCompany={setCompany} company={company} />
            <button className="userBtn btn-theme me-2">
              {t("receiving.export")}
            </button>
            <Link
              className="userBtn btn-theme"
              to="../addreceivinglist"
              state={company}
            >
              {t("receiving.addNew")}
            </Link>
          </div>
        </div>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th> {t("receiving.receivingListName")}</th>
                <th> {t("receiving.deliveryReceivingDate")}</th>
                <th> {t("receiving.vendorDistributor")}</th>
                <th> {t("receiving.item")}</th>
                <th> {t("receiving.unitMeasure")}</th>
                <th> {t("receiving.packCount")}</th>
                <th> {t("receiving.qtyToReceive")}</th>
                <th> {t("receiving.receivedLocaiton")}</th>
                <th> {t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.datalists && data?.datalists?.length > 0 ? (
                data?.datalists?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>
                        {moment(item?.details?.deliveryDate).format("ll")}
                      </td>
                      <td>{item?.details?.vendorName}</td>
                      <td>
                        {i18next?.language == "es"
                          ? item?.itemDetails?.es_name
                          : item?.itemDetails?.en_name}
                      </td>
                      <td>{item?.details?.measureUnit}</td>
                      <td>{item?.details?.packCount}</td>
                      <td>{item?.details?.receivedQuantity}</td>
                      <td>
                        {item?.assignAll == "true" || item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i?.name)?.join(", ")}
                      </td>
                      <td>
                        <Link
                          to={`../addreceivinglist?id=${item?._id}`}
                          role="button"
                          state={company}
                        >
                          <img src="/images/Edit.svg" alt="" />
                        </Link>

                        <img
                          src="/images/Delete.svg"
                          alt="delete"
                          onClick={() => setShowDelete(item?._id)}
                          role="button"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("receiving.noReceivingList")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.datalists?.length}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default ReceivingList;
