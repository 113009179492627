import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyDropDown from "../../components/CompanyDropDown";
import DeleteModal from "../../components/DeleteModal";
import DownloadSample from "../../components/DownloadSample";
import ImportFile from "../../components/ImportFile";
import Loader from "../../components/Loader/Loader";
import useSlider from "../../hooks/useSlider";
import {
  deleteCatergory,
  downloadCategoryExcel,
  getCategory,
  handleImportCatergory,
} from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const CategoryList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [company, setCompany] = useState("");
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [file, setFile] = useState("");

  const { data, refetch } = useQuery({
    queryKey: ["category-list", page, limit, company?.domain],
    queryFn: async () => {
      const resp =
        company?.domain && (await getCategory(company?.domain, page, limit));
      return resp?.data?.data ?? [];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteCatergory(company?.domain, id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const handleSampleDownload = async (domain) => {
    setShowDownload(false);
    try {
      let resp = await downloadCategoryExcel(domain);
      const blobUrl = window.URL.createObjectURL(resp?.data);
      window.open(blobUrl);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleImportFile = async (domain) => {
    let formData = new FormData();
    formData.append("file", file);
    fileUploadMutation.mutate({ formData, domain });
  };

  const fileUploadMutation = useMutation({
    mutationFn: ({ formData, domain }) =>
      handleImportCatergory(formData, domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      setShowImport(false);
      refetch();
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("category.categories")}</h2>
          <div className="d-flex align-items-center gap-2">
            <CompanyDropDown setCompany={setCompany} company={company} />
            <button
              className="userBtn btn-theme me-2"
              onClick={() => setShowDownload(company)}
            >
              {t("globals.exportSample")}
            </button>
            <label
              className="userBtn btn-theme me-2"
              role="button"
              htmlFor="import-catergory"
            >
              {t("globals.import")}
            </label>
            <input
              type="file"
              className="d-none"
              id="import-catergory"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => {
                if (
                  e.target.files[0]?.type !==
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                  toastAlert("error", t("errorMsg.xlsxFormat"));
                  return;
                }
                setFile(e.target.files[0]);
                setShowImport(company);
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
          </div>
        </div>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("category.categoryIcon")}</th>
                <th>{t("category.categoryName")}</th>
                <th>{t("category.categoryNameSpanish")}</th>
                <th>{t("category.location")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.categories && data?.categories?.length > 0 ? (
                data?.categories?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>
                        <img
                          src={item?.icons ? item?.icons : "/images/dummy.jpg"}
                          alt="cat-icon"
                          width={50}
                          height={50}
                        />
                      </td>
                      <td>{item?.en_name}</td>
                      <td>{item?.es_name}</td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i.name)?.join(", ")}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("category.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.categories?.length}
        />
        <DeleteModal
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          handleDelete={deleteMutation.mutate}
        />
        <DownloadSample
          show={showDownload}
          handleClose={() => setShowDownload(false)}
          handleDownload={handleSampleDownload}
        />
        <ImportFile
          show={showImport}
          handleClose={() => setShowImport(false)}
          handleImportFile={handleImportFile}
          file={file}
        />
      </div>
      {fileUploadMutation?.isPending && <Loader />}
    </div>
  );
};

export default CategoryList;
