import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CrossIcon } from "../SvgIcons/allIcons";

const ImportFile = ({ show, handleClose, handleImportFile, file }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header className="deleteUser_ModalHeader" closeButton={false}>
        <Modal.Title>
          {t("globals.doYouWantToImport", { company: show?.label })}
        </Modal.Title>
        <Button variant="link" onClick={handleClose} className="close_Btn">
          <CrossIcon />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p className="ms-4">
          File Name : <span>{file?.name}</span>
        </p>
      </Modal.Body>

      <Modal.Footer className="deleteUser_ModalFooter">
        <Button
          variant="secondary"
          className="userBtn btn-theme"
          type="button"
          onClick={() => handleImportFile(show?.domain)}
        >
          {t("globals.import")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportFile;
