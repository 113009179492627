import { useMutation, useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import CompanyDropDown from "../../components/CompanyDropDown";
import DeleteModal from "../../components/DeleteModal";
import useSlider from "../../hooks/useSlider";
import { deleteDataList, getDataList } from "../../services/services";
import { UploadIcon } from "../../SvgIcons/allIcons";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const PrepList = () => {
  const { state } = useLocation();
  const [company, setCompany] = useState(!!state ? state : "");
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["data-list", page, limit, company?.domain],
    queryFn: async () => {
      const resp =
        company?.domain &&
        (await getDataList(
          page,
          limit,
          search,
          constant.DATA_LIST.PREP_LIST,
          company?.domain,
          true,
          true
        ));
      return resp?.data?.data ?? [];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteDataList(id, company?.domain),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const [file, setFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // if (acceptedFiles && acceptedFiles.length > 0) {
    //   const uploadedFile = acceptedFiles[0];
    //   const fileUrl = URL.createObjectURL(uploadedFile);
    //   setFile(fileUrl);
    // }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("prepList.prepList")} </h2>
          <div className="d-flex align-items-center gap-2">
            <CompanyDropDown setCompany={setCompany} company={company} />
            <button className="userBtn btn-theme me-2">
              {t("globals.export")}
            </button>
            <Link className="userBtn btn-theme" to="../add-prep-list" state={company}>
              {t("globals.addNew")}
            </Link>
          </div>
        </div>
        {data?.datalists?.length > 0 ? (
          <div className="table-responsive tableOut mt-4">
            <table className="table tableDashboard">
              <thead className="thead-dark">
                <tr>
                  <th>{t("prepList.productionName")}</th>
                  <th>{t("prepList.items")}</th>
                  <th>{t("prepList.qunatityLabel")}</th>
                  <th>{t("prepList.labelProfile")}</th>
                  {/* <th>{t("prepList.preppedQty")}</th> */}
                  <th>{t("prepList.prepLocation")}</th>
                  <th>{t("globals.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.datalists && data?.datalists?.length > 0 ? (
                  data?.datalists?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>
                          {i18next?.language == "es"
                            ? item?.itemDetails?.es_name
                            : item?.itemDetails?.en_name}
                        </td>
                        <td>{item?.details?.labelQuantity}</td>
                        <td>
                          {item?.details?.labelProfile
                            ? item?.details?.labelProfile
                            : "N/A"}
                        </td>
                        {/* <td>
                          {item?.details?.prepQuantity
                            ? item?.details?.prepQuantity
                            : "N/A"}
                        </td> */}
                        <td>
                          {item?.assignAll
                            ? t("prepList.allLocations")
                            : item?.locations?.map((i) => i?.name)?.join(", ")}
                        </td>
                        <td>
                          <Link
                            to={`../add-prep-list?id=${item?._id}`}
                            state={company}
                            role="button"
                          >
                            <img src="/images/Edit.svg" alt="" />
                          </Link>

                          <img
                            src="/images/Delete.svg"
                            alt="delete"
                            onClick={() => setShowDelete(item?._id)}
                            role="button"
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      <h4>{t("prepList.noPrepList")}</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalPages={data?.total_pages}
              total={data?.total}
              length={data?.datalists?.length}
            />
          </div>
        ) : (
          <div className="file_Upload mt-3">
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              {!file && !isDragActive && (
                <p className="text-center">
                  {t("globals.dragAndDrop")} <br /> {t("globals.orImport")}
                </p>
              )}
              {isDragActive ? (
                <p>{t("globals.dropFilesHere")}</p>
              ) : (
                file && (
                  <div className="image-preview">
                    <img src={file} alt="Preview" className="image-preview" />
                  </div>
                )
              )}
              {!file && !isDragActive && (
                <button className="userBtn btn-theme fontSize18 uploadFile_Btn">
                  <UploadIcon />{" "}
                  <span className="ms-2">{t("globals.import")}</span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default PrepList;
