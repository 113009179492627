import React from "react";
import { FaAlignCenter, FaAlignLeft, FaAlignRight, FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { MdOutlineHorizontalRule } from "react-icons/md";

const FormatEditor = () => {
  return (
    <div className="feature_Editor features">
      <div className="featureTxt_Parent">
        <h2 className="fontSize24">Format Editor</h2>
      </div>
      <div className="feature_Editor_Child">
        <p className="fontSize14">
          <span>You are editing:</span> Item Name
        </p>
        <div className="center d-flex align-items-center justify-content-between feature_Range">
          <label htmlFor="grid" className="snapgrid_Label fontSize14">
            Snap to Grid:
          </label>
          <input type="checkbox" />
        </div>
        <div className="feature_Range">
          <div className="d-flex justify-content-between align-items-center">
            <span>Min</span>
            <span>Max</span>
          </div>
          <input type="range" className="mt-3" />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <h3 className="range_Number fontSize14">15</h3>
            <h3 className="range_Number fontSize14">150</h3>
          </div>
        </div>
        <div className="feature_Range">
          <label htmlFor="name" className="companyLabel">
            Font
          </label>
          <select name="state" id="state" className="state_Select">
            <option value="Haryana">Calibri</option>
            <option value="Haryana">Haryana</option>
            <option value="Punjab">Punjab</option>
          </select>
        </div>
        <div className="feature_Range">
          <h3 className="fontSize14 mb-2">Text Position</h3>
          <div className="text_Align_Container">
            <p className="leftTxt_Icon">
              <FaAlignLeft />
            </p>
            <p className="leftTxt_Icon active">
              <FaAlignCenter />
            </p>
            <p className="leftTxt_Icon">
              <FaAlignRight />
            </p>
          </div>
        </div>
        <div className="feature_Range d-flex justify-content-between align-items-center">
          <h3 className="fontSize14">Bold Text</h3>
          <h3 className="txt_Bold fontSize24">B</h3>
        </div>
        <div className="feature_Range">
          <h3 className="fontSize14 mb-2">Vertical Alignment</h3>
          <div className="text_Align_Container">
            <p className="verticle_Align">
              <FaAngleDown />
            </p>
            <p className="verticle_Align active">
              <MdOutlineHorizontalRule />
            </p>
            <p className="verticle_Align">
              <FaAngleUp />
            </p>
          </div>
          <div className="mt-3">
            <label htmlFor="name" className="companyLabel">
              Font
            </label>
            <select name="state" id="state" className="state_Select">
              <option value="Haryana">Center</option>
              <option value="Haryana">Left</option>
              <option value="Punjab">Right</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormatEditor;
